import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59')
];

export const server_loads = [0,3,4,5];

export const dictionary = {
		"/": [~6],
		"/(protected)/dashboard": [~10,[2]],
		"/(protected)/documents/[docId]": [~11,[2]],
		"/(auth)/error": [7],
		"/(protected)/holidays/[holidayId]": [~12,[2]],
		"/(protected)/logout": [~13,[2]],
		"/(protected)/notifications": [~14,[2]],
		"/(protected)/organizations": [~15,[2]],
		"/(protected)/organizations/[orgId]": [16,[2,3]],
		"/(protected)/organizations/[orgId]/branding": [~17,[2,3]],
		"/(protected)/organizations/[orgId]/documents": [~18,[2,3]],
		"/(protected)/organizations/[orgId]/holidays": [~19,[2,3]],
		"/(protected)/organizations/[orgId]/integrations": [~20,[2,3]],
		"/(protected)/organizations/[orgId]/locations": [~21,[2,3]],
		"/(protected)/organizations/[orgId]/locations/[locId]": [~22,[2,3]],
		"/(protected)/organizations/[orgId]/locations/[locId]/documents": [~23,[2,3]],
		"/(protected)/organizations/[orgId]/locations/[locId]/staff": [~24,[2,3]],
		"/(protected)/organizations/[orgId]/locations/[locId]/templates": [~25,[2,3]],
		"/(protected)/organizations/[orgId]/procedures": [~26,[2,3]],
		"/(protected)/organizations/[orgId]/schedule": [~27,[2,3]],
		"/(protected)/organizations/[orgId]/settings": [~28,[2,3]],
		"/(protected)/organizations/[orgId]/staff": [~29,[2,3]],
		"/(protected)/organizations/[orgId]/timesheets": [~30,[2,3]],
		"/(auth)/otp": [8],
		"/(protected)/procedures/[procedureId]": [~31,[2]],
		"/(protected)/templates": [~32,[2]],
		"/(protected)/templates/[templateId]": [~33,[2]],
		"/(protected)/usage": [~34,[2,4]],
		"/(protected)/usage/announcements": [~35,[2,4]],
		"/(protected)/usage/areas": [~36,[2,4]],
		"/(protected)/usage/coverage": [~37,[2,4]],
		"/(protected)/usage/credentials": [~38,[2,4]],
		"/(protected)/usage/documents": [~39,[2,4]],
		"/(protected)/usage/holidays": [~40,[2,4]],
		"/(protected)/usage/integrations": [~41,[2,4]],
		"/(protected)/usage/locations": [~42,[2,4]],
		"/(protected)/usage/messages": [~43,[2,4]],
		"/(protected)/usage/notifications": [~44,[2,4]],
		"/(protected)/usage/organizations": [~45,[2,4]],
		"/(protected)/usage/palette": [~46,[2,4]],
		"/(protected)/usage/procedures": [~47,[2,4]],
		"/(protected)/usage/requests": [~48,[2,4]],
		"/(protected)/usage/templates": [~49,[2,4]],
		"/(protected)/usage/users": [~50,[2,4]],
		"/(protected)/users": [~51,[2]],
		"/(protected)/users/[userId]": [52,[2,5]],
		"/(protected)/users/[userId]/credentials": [~53,[2,5]],
		"/(protected)/users/[userId]/leave": [~54,[2,5]],
		"/(protected)/users/[userId]/pay-rates": [~55,[2,5]],
		"/(protected)/users/[userId]/preferences": [~56,[2,5]],
		"/(protected)/users/[userId]/request-history": [~57,[2,5]],
		"/(protected)/users/[userId]/shifts": [~58,[2,5]],
		"/(protected)/users/[userId]/unavailability": [~59,[2,5]],
		"/(auth)/verify-request": [9]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';